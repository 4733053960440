<template>
  <section class="box">
    <p class="mb-6">
      Du bist leider nicht autorisiert, diese Applikation zu verwenden.<br>
      Zugriff auf das TopQ Tool haben die TopQ verantwortlichen Personen mit ihren persönlichen Login-Daten. Bitte melde dich entweder mit einem anderen Account an, oder melde dich bei <a href="mailto:topq@toppharm.ch">TopQ</a>
    </p>
    <b-button v-if="isOnline" type="is-primary" @click.prevent="login" :loading="loggingIn">
      Erneut versuchen
    </b-button>
    <div v-else class="has-text-danger has-text-weight-bold">Die erneute Anmeldung ist nur mit Internetzugang möglich</div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import UserService from '@/services/user.service'

export default {
  data () {
    return {
      loggingIn: false
    }
  },
  computed: {
    ...mapGetters({
      isOnline: 'global/isOnline'
    })
  },
  methods: {
    login () {
      this.loggingIn = true
      UserService.signOut()
    }
  }
}
</script>
